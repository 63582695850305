*,
*::after,
*::before
  margin: 0
  padding: 0
  box-sizing: inherit

ul, ul > li
  list-style: none

button
  background: none
  border: none
  outline: none
  +appearance(none)
  color: inherit
  font-family: inherit

img
  max-width: 100%

html
  font-size: 62.5%
  scroll-behavior: smooth

input, textarea, select
  outline: none
  -webkit-appearance: none

textarea
  -webkit-appearance: none
  ::-webkit-resizer 
    display: none

main
  display: block
  
video
  width: 100%
  display: block
  outline: none

table
  width: 100%

address
  font-style: normal

a
  outline: none
  text-decoration: none
  color: inherit
  &:hover,
  &:active
    text-decoration: none
