@import 'styles/sass/abstracts'
  
.label
  display: inline-flex
  align-items: center
  border-radius: $radius-xs
  cursor: default
  &--clickable
    cursor: pointer
    
.icon
  margin-left: 8px
  svg
    +style-icon(12px, currentColor)

.text
  font-weight: $fw-black
  text-transform: uppercase

.label
  // size
  &--sm
    padding: 4px 8px
    +typoTextXs
  &--md
    padding: 6px 10px
    +typoTextSm
    &.label--responsive
      +res-sm-max
        padding: 4px 8px
        +typoTextXs
    // non so perchè ma quello con l'ombra è più grande su Figma
    &.label--shadow 
      padding-top: 7px
      padding-bottom: 7px

  // color
  &--blue
    background: $primary-default
    color: $white
  &--light-blue
    background: $neutral-1
    color: $primary-default
  &--white
    background: $white
    color: $primary-default

  // shadow
  &--shadow
    box-shadow: $shadow-sm

  // bordered
  &--bordered
    border: 1px solid $neutral-4
