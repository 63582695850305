@import styles/sass/abstracts

.root
  display: flex
  justify-content: space-between
  background: white
  padding: 32px
  &:not(:last-child)
    border-bottom: 1px solid $neutral-5
  +res-sm-max
    padding: 20px
  &--image
    padding: 20px 32px

.left
  display: flex
  align-items: center
  +res-sm-max
    align-items: flex-start
    flex-direction: column

.right
  display: flex
  align-items: center
  +res-sm-max
    align-items: flex-end

.image
  margin-right: 20px
  +res-sm-max
    margin-bottom: 20px

.title
  +font(20, 22, 700)
  margin-right: 20px
  +res-sm-max
    +font(16, 22, 700)

.number
  +font(24, 26, 700)
  color: $accent-default
  &:hover
    text-decoration: underline
  +res-sm-max
    +font(18, 25, 700)
    text-decoration: underline

