@import 'styles/sass/abstracts'
  
.formCheckbox
  position: relative
  display: block
  cursor: pointer
  color: $primary-dark
  min-height: 20px
  +typoFormMd
  &--disabled
    color: $neutral-4
    cursor: default
  &:not(.formCheckbox--disabled)
    .inputWrap
      cursor: pointer

.inputWrap
  padding-left: 11px
  display: block

.label, .content
  display: block

.content
  padding-left: 16px

.input
  position: absolute
  left: -9999px

  &+ .styledInput
    position: absolute
    left: 0
    top: 1px
    svg
      +style-icon(20px, currentColor)

  &:disabled
    &+ .styledInput
      svg
        +color-icon($neutral-4)
    &~ .label
      color: $neutral-4

.accordionTrigger
  display: block
  color: $accent-default
  cursor: pointer
  
.formCheckbox
  // Status
  &--error
    .input
      &+ .styledInput
        color: $error-default

  // color
  &--white
    color: $white

  // size
  &--sm
    .label
      padding-left: 11px
      +typoBase(14px, 20px)

  // accordion
  &--accordion
    max-width: 100%
    &:not(.formCheckbox--accordionOpen)
      .label
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis