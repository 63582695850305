@import 'styles/sass/abstracts'
  
.breadcrumbs
  display: inline-flex
  flex-wrap: wrap
  align-items: center
  padding: 4px 8px
  background: $white
  color: $primary-light
  border-radius: $radius-lg
  overflow: hidden
  height: 26px

  &--n2
    .item
      &:not(:first-child)
        .itemLabel
          max-width: calc(100vw - #{$wcont-padding * 2 + 30px + 12px}) // totale - (padding container + padding e margin della home e della freccina)
          @media screen and (min-width: $wcont-width-md)
            max-width: #{$wcont-width-md - ($wcont-padding * 2 + 30px + 12px)}

  &--n3
    .item
      &:not(:first-child)
        .itemLabel
          max-width: calc(50vw - #{($wcont-padding * 2 + 30px + 12px * 2) * .5})
          @media screen and (min-width: $wcont-width-md)
            max-width: #{($wcont-width-md - ($wcont-padding * 2 + 30px + 12px * 2)) * .5}
  &--n4
    .item
      &:not(:first-child)
        .itemLabel
          max-width: calc(33.33vw - #{($wcont-padding * 2 + 30px + 12px * 3) * .33}) 
          @media screen and (min-width: $wcont-width-md)
            max-width: #{($wcont-width-md - ($wcont-padding * 2 + 30px + 12px * 3)) * .33}




.item
  +typoTextXs
  display: flex
  align-items: center
  // Variant
  &--white
    color: white

.itemLabel
  line-height: 18px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  &--last
    margin-right: 0
    font-weight: $fw-black
  svg
    +style-icon(12px, currentColor)
    
.icon
  +style-icon(10px, currentColor)
  margin-right: 2px
