@import styles/sass/abstracts

.alert
  display: flex
  flex-direction: row
  align-items: center
  gap: 4px
  +typoDescriptionXs
  svg
    +style-icon(16.67px, $secondary-dark)
