@import 'styles/sass/abstracts'
  
.input
  width: 100%
  border: 1px solid $border-color
  color: $black
  outline: none
  +typoFormMd
  padding: 11px 12px
  +transition(border-color .2s)
  +placeholder-style
    color: $black
  &:focus
    border-color: $primary-default

.formInput
  // Status
  &--error
    .input
      border-color: $error-default
