@import styles/sass/abstracts

.cta
  display: flex
  flex-direction: row
  align-items: center
  gap: 4px
  +font(16, 19, 600)
  color: $accent-default
  cursor: pointer
  svg
      +style-icon(12.99px, currentColor)
.decoration
  height: 1px
  background: $accent-default
  border-radius: 1px
