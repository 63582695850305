@import 'styles/sass/abstracts'

.formFile
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: $gap-sm-1

.container
  display: flex
  flex-direction: row
  gap: $gap-sm-1
  width: 75%
  +res-sm-max
    width: 100%
    flex-direction: column

.inputWrap
  position: relative
  +typoFormMd

.load
  background-color: unset
  color: $accent-dark
.load:hover
  color: $white
.loadDesktop
  @extend .load
  +res-sm-max
    display: none
.loadMobile
  @extend .load
  width: 100%
  +res-lg-min
    display: none

.control
  display: block
  width: 100%
  border: 1px solid #D4D3E7
  border-radius: 4px
  background: $white
  padding: 11px 12px

.input
  position: absolute
  left: -9999px   

.content
  display: block

.filename, .label
  display: block
  white-space: nowrap
  overflow-x: hidden
  text-overflow: ellipsis

.remove
  margin-left: 8px
  svg
    display: block
    +style-icon(20px, $primary-dark)

.formFile
  &:not(.formFile--disabled)
    .control
      cursor: pointer
    .remove
      cursor: pointer

  &--disabled 
    .inputWrap
      color: $primary-light

  // Status
  &--error
    .inputWrap
      border-color: $error-default

  &--with-value
    .control
      width: calc(100% - 28px)

.error
  color: $error-default
  svg
    +style-icon(16.67px, $error-default)