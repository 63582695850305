@import 'styles/sass/abstracts'
  
.input
  width: 100%
  border: 1px solid $border-color
  color: $black
  outline: none
  +typoFormMd
  padding: 11px 12px
  +transition(border-color .2s)
  +placeholder-style
    color: $black
  &:focus
    border-color: $primary-default

.inputWrap
  position: relative

.toggler
  position: absolute
  cursor: pointer
  display: block
  top: calc(50% - 10px)
  right: 16px
  svg
    display: block
    +style-icon(20px, currentColor)

.formInputPassword
  &--with-toggler
    .input
      padding-right: 52px
        
  // Status
  &--error
    .input
      border-color: $error-default
