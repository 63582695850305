@import 'styles/sass/abstracts'
  
.stepBar
  display: flex
  padding: 20px 16px
  +boxShadow
  +res-sm-max
    justify-content: center

.item
  display: flex
  flex-direction: row
  align-items: center
  position: relative
  gap: 7px
  &:not(:last-child)
    padding-right: 44px
  &:not(:last-child)::after
    content: ""
    position: absolute
    right: 10px
    top: 16px
    height: 1px
    width: 24px
    background-color: red

  .dot
    display: block
    width: 32px
    height: 32px
    +border-radius(50%)
    border-width: 1px
    border-style: solid
    +typoTextMd
    line-height: 30px // altezza del dot - bordo
    text-align: center

  .label
    display: block
    color: $primary-default
    +typoTextSm
    text-align: center
    // padding-right: 20px
    +res-sm-max
      display: none


  &--past
    &:not(:last-child)::after
      background: $primary-default
    .dot
      border-color: $primary-default
      color: $primary-default
      padding: 6px
      svg
        +style-icon(18px, $primary-default)
    .label
      color: $primary-default

  &--active
    &:not(:first-child)::before
      background: $primary-default
    &:not(:last-child)::after
      background: #D3DFF0
    .dot
      background: $primary-default
      border-color: $primary-default
      color: $white
    .label
      color: $primary-default
      font-weight: 700

  &--future
    &:not(:first-child)::before
      background: #D3DFF0
    &:not(:last-child)::after
      background: #D3DFF0
    .dot
      border-color: #D3DFF0
      color: #D3DFF0
    .label
      color: #D3DFF0