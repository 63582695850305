@import styles/sass/abstracts

.loaderOverlay
  position: fixed
  z-index: 999999
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  background: rgba($white, .6)
  svg
    display: block
    +size-icon(50px)
    animation: rotation 1s infinite linear