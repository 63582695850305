/*
@font-face 
  font-family: 'SmartKid'
  src: url('fonts/SmartKid-Bold.eot')
  src: url('fonts/SmartKid-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/SmartKid-Bold.svg#Smart_Kid') format('svg'), url('fonts/SmartKid-Bold.ttf') format('truetype'), url('fonts/SmartKid-Bold.woff') format('woff'), url('fonts/SmartKid-Bold.woff2') format('woff2')
  font-weight: 700
  font-style: normal
  font-display: swap
  
body
  padding: 0!important
  box-sizing: border-box
  position: relative
  font-family: $font-primary
  color: $primary-dark
  background-color: $white
  +typoTextMd
  +scrollbar-style
  scroll-behavior: smooth
  &.w-noscroll
    overflow: hidden
  &.w-noscrollnav
    +res-md-max
      overflow: hidden
