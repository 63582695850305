@import styles/sass/abstracts

.help
  display: inline-flex
  align-items: center
  padding: 10px
  +border-radius(12px)
  background-color: $neutral-4
  color: $primary-dark
  cursor: pointer
.icon
  +style-icon(24px, currentColor)
.label
  +typoTextSm
  font-weight: 900
  margin-left: 10px
