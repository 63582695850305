@import 'styles/sass/abstracts'

.no-margin-mobile
  +res-sm-max
      margin: unset!important
      padding: 0!important

.wContainer
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  
  &--md
    max-width: $wcont-width-md

  &--sm
    max-width: $wcont-width-sm

  &--xs
    max-width: $wcont-width-xs
    
  &--xxs
    max-width: $wcont-width-xxs

  &--md-no-mobile
    @extend .no-margin-mobile
    max-width: $wcont-width-md

  &--sm-no-mobile
    @extend .no-margin-mobile
    max-width: $wcont-width-sm

  &--xs-no-mobile
    @extend .no-margin-mobile
    max-width: $wcont-width-xs
