@import 'styles/sass/abstracts'
  
.inputWrap
  position: relative
  display: block
  cursor: pointer
  color: $primary-dark

.labelRadio
  padding: 10px 0 0 0
  font-size: 14px

.label    
  display: block
  padding-left: 24px

.input 
  position: absolute
  left: -9999px

.styledInput
  position: absolute
  left: 0
  top: 1px
  svg
    +style-icon(20px, currentColor)

.option
  min-height: 20px
  +typoFormMd
  &--disabled
    .input-wrap
      color: $neutral-4
      cursor: default
    .styledInput
      svg
        +color-icon($neutral-4)
    .label
      color: $neutral-4

  // Status
  &--error
    .styledInput
      color: $error-default

.options
  padding-top: 13.5px
  > *
    margin-bottom: 16px
  +res-md-min
    display: flex
    flex-wrap: wrap
    > *
      &:not(:last-child)
        margin-right: 40px