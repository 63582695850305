@import 'styles/sass/abstracts'
  
.formToggle
  position: relative
  display: block
  cursor: pointer
  color: $primary-dark
  +typoFormMd

.inputWrap
  display: block
  min-height: 20px

.label    
  display: block
  padding-left: 43px

.input
  position: absolute
  left: -9999px

.styledInput
  position: absolute
  left: 0
  display: block
  background-color: $neutral-5
  +transition(background-color .2s linear)
  top: 1px
  width: 35px
  height: 20px
  +border-radius(20px)
  &::before
    content: ""
    position: absolute
    top: 3px
    left: 3px
    width: 14px
    height: 14px
    display: block
    +border-radius(50%)
    background: $white
    +transition(left .2s linear)

.formToggle
  // Checked
  &--checked
    .styledInput
      background-color: $accent-default
      &::before
        left: 50%
  
  &:not(.formToggle--disabled)
    .inputWrap
      cursor: pointer

  // Disabled
  &--disabled
    color: $neutral-5
    cursor: default
    .styledInput
      background: $neutral-2
    .label
      color: $neutral-4
  
  // Status
  &--error
    .input
      &+ .styledInput
        color: $error-default
