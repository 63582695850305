@import styles/sass/abstracts

.boxCard
  width: 100%
  display: flex
  flex-direction: row
  justify-content: center
  align-items: flex-start
  padding: 40px 42px
  gap: $gap-md
  +whiteBoxShadow
  +res-sm-max
    padding: 20px
    align-items: flex-start
    justify-content: unset
  &--half
    max-width: 49%
    +res-md-max
      max-width: 100%

  .icon
    svg
      display: block
      +size-icon(100px)
      +res-sm-max
        +size-icon(60px)
  .iconDesktop
    +res-sm-max
      display: none
  .iconMobile
    display: none
    +res-sm-max
      display: block

  .info
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0
    gap: $gap-md
    .title
      +font(24, 110%, 700)
      +res-sm-max
        +font(24, 26.4, 700)
        display: flex
        flex-direction: row
        align-items: center
        gap: $gap-md
    .subTitle
      +font(16, 140%, 400)
      +res-sm-max
        +font(16, 22.4, 400)
    .cta
      color: $accent-default
      +font(16, 19.2, 600)
      text-decoration: underline
      svg
        +style-icon(18px, currentColor)
