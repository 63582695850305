@import 'styles/sass/abstracts'

.icon
  margin-right: 5px
  svg
    +style-icon(24px, currentColor)
.label
  +typoTextMd

.trigger
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 12px 12px 32px
  +border-radius(20px 20px 0 0)
  background-color: $neutral-4
  cursor: pointer
  
  &--active
    background-color: $white
    .label
      font-weight: 900

.tab
  position: relative // per z-index
  display: none
  background-color: $white
  +border-radius(20px)
  padding: 45px 28px 37px
  margin-top: -20px
  +res-sm-max
    padding: 17px 4px
  &--active
    display: block