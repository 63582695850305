@import 'styles/sass/abstracts'
  
=buttonSm
  +typoButtonSm
  .icon, .loader
    +size-icon(20px)
  .loader
    top: calc(50% - 10px)
    width: 20px
  &.button--primary, &.button--secondary, &.button--social
    padding: 8px 14px
    &.button--no-label
      padding: 8px
    &.button--no-label-desktop
      +res-md-min
        padding: 8px
  &.button--ghost
    padding: 10px 0
  
  
=buttonMd
  +font(20, 22, 700)
  .icon, .loader
    svg 
      +size-icon(24px)
  .loader
    top: calc(50% - 12px)
    width: 24px
  &.button--primary, &.button--secondary, &.button--social
    padding: 10px 18px
    &.button--no-label
      padding: 10px
    &.button--no-label-desktop
      +res-md-min
        padding: 10px
  &.button--ghost
    padding: 12px 0

.button
  border-radius: 4px
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  position: relative
  +transition(#{"background-color .2s linear, color .2s linear, opacity .2s linear"})
  &:not(:disabled)
    cursor: pointer
  &:disabled
    opacity: .5

.label
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

.icon, .loader
  svg
    display: block
    +color-icon(currentColor)
  
.loader
  position: absolute
  left: 0
  right: 0
  margin: 0 auto
  svg
    animation: rotation 1s infinite linear

.button
  // Loading
  &--loading
    .label, .icon
      opacity: 0

  // Variant
  &--primary
    background-color: $accent-default
    border: 2px solid $accent-default
    color: $white 

    &:hover:not(:disabled)
      background-color: $accent-dark
      border-color: $accent-dark

    &:disabled
      background-color: $neutral-5
      border-color: $neutral-5

  &--secondary
    border: 2px solid currentColor
    color: $accent-default

    &:hover:not(:disabled)
      color: $accent-dark

    &:disabled
      color: $accent-light
    
  &--ghost
    color: $accent-default
    padding: 0

    &:hover:not(:disabled)
      color: $accent-dark

    &:disabled
      color: $accent-light

  &--social
    color: $primary-dark
    background: $neutral-2

    &:hover:not(:disabled)
      color: $primary-dark

  // Color
  &--color-white
    &.button--primary
      background-color: $white
      border: 2px solid $white
      color: $accent-default 

      &:hover:not(:disabled)
        background-color: $neutral-2
        border-color: $neutral-2

      &:disabled
        background-color: $neutral-1
        border-color: $neutral-1

    &.button--secondary
      color: $white

      &:hover:not(:disabled)
        color: $neutral-2

      &:disabled
        color: $neutral-5
      
    &.button--ghost
      color: $white

      &:hover:not(:disabled)
        color: $neutral-2

      &:disabled
        color: $neutral-5

  &--color-primary
    &.button--primary
      background-color: $primary-default
      border: 2px solid $primary-default
      color: $white 

      &:hover:not(:disabled)
        background-color: $primary-dark
        border-color: $primary-dark

      &:disabled
        background-color: $neutral-5
        border-color: $neutral-5

    &.button--secondary
      color: $primary-default

      &:hover:not(:disabled)
        color: $primary-dark

      &:disabled
        color: $neutral-5
      
    &.button--ghost
      color: $primary-default

      &:hover:not(:disabled)
        color: $primary-dark

      &:disabled
        color: $neutral-5


  // Size
  &--sm
    +buttonSm

  &--md
    +buttonMd
    &.button--responsive
      +res-sm-max
        +buttonSm

  // Label e/o icon
  &--icon-right
    .icon
      margin-left: 8px
      order: 1
  &--icon-left
    .icon
      margin-right: 8px
  &--no-label
    .icon
      margin: 0
  &--no-label-desktop
    +res-md-min
      .icon
        margin: 0
  