@mixin mb($value)
  margin-bottom: #{$value * $space-unit}

@mixin mt($value)
  margin-top: #{$value * $space-unit}

@mixin ml($value)
  margin-left: #{$value * $space-unit}

@mixin mr($value)
  margin-right: #{$value * $space-unit}

@mixin mx($value)
  +ml($value)
  +mr($value)

@mixin my($value)
  +mt($value)
  +mb($value)

@mixin pb($value)
  padding-bottom: #{$value * $space-unit}

@mixin pt($value)
  padding-top: #{$value * $space-unit}

@mixin pl($value)
  padding-left: #{$value * $space-unit}

@mixin pr($value)
  padding-right: #{$value * $space-unit}

@mixin px($value)
  +pl($value)
  +pr($value)

@mixin py($value)
  +pt($value)
  +pb($value)