@import 'styles/sass/abstracts'
  
.formError
  position: relative
  +py(1)
  +pl(8)
  +mt(1.5)
  +typoTextSm
  color: $error-default

.icon
  position: absolute
  top: 0
  left: 0
  +style-icon(24px, currentColor)