@import 'styles/sass/abstracts'
  
.select
  \:global(.react-select__input-container)
    padding: 0
    margin: 0
  \:global(.react-select__control)
    padding: 11px 12px!important

.error
  margin-top: 8px

