@import 'styles/sass/abstracts'
  
.head
  display: flex
  align-items: center
  margin-bottom: 4px

.helper
  margin-left: 4px
  cursor: pointer
  svg
    +style-icon(20px, $primary-dark)

.children
  +res-md-min
    display: flex
    > * 
      flex: 1 0 0 
      &:not(:last-child)
        +mr(4)
  +res-sm-max
    > *
      &:not(:last-child)
        +mb(4)

.formField
  // Size
  &--md
    .label
      +typoLabelMd
  &--required
    .label
      &::after
        content: " *"

  // color
  &--white
    .label
      color: $white

  // space
  &--space-sm
    margin-bottom: 8px
  &--space-md
    margin-bottom: 16px
  &--space-lg
    margin-bottom: 24px
  &--space-xl
    margin-bottom: 40px
  &--space-none
    margin-bottom: 0
    
  // layout
  &--layout-sm
    +res-md-min
      width: 25%
  &--layout-md
    +res-md-min
      width: 50%
  &--layout-lg
    +res-md-min
      width: 75%
  &--layout-full
    +res-md-min
      width: 100%